import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo-yw-purple.png";
import googleIconImageSrc from "images/google-icon.png";
import { Alert } from "@mantine/core";
// import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useForm } from "@mantine/form";
import { InputWrapper, Input as InputEl, TextInput } from "@mantine/core";
import { AlertCircle } from "tabler-icons-react";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-3 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "登录到用户中心",
  // socialButtons = [
  //   {
  //     iconImageSrc: googleIconImageSrc,
  //     text: "Sign In With Google",
  //     url: "https://baidu.com"
  //   },
  //   {
  //     iconImageSrc: twitterIconImageSrc,
  //     text: "Sign In With Twitter",
  //     url: "https://weibo.com"
  //   }
  // ],
  submitButtonText = "登 录",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "/#/sign-up",
}) => {
  const [loginFail, setLoginFail] = useState(null)
  const form = useForm({
    initialValues: {
      account: "",
      password: "",
    },
    validate: {
      account: (value) => {
        return /^\S+@\S+$/.test(value) ||
          /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
            value
          )
          ? null
          : "账户应该是手机号或者电子邮箱";
      },
    },
  });
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                {loginFail != null && (
                  <Alert
                    icon={<AlertCircle size={16} />}
                    title="糟糕!"
                    color="red"
                  >
                    {loginFail}
                  </Alert>
                )}
                {/* <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton key={index} href={socialButton.url}>
                    <span className="iconContainer">
                      <img src={socialButton.iconImageSrc} className="icon" alt=""/>
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer> */}
                <DividerTextContainer>
                  <DividerText>请使用邮箱或者手机登录</DividerText>
                </DividerTextContainer>
                <form
                  onSubmit={form.onSubmit((values) => {
                    setLoginFail("账户不存在");
                  })}
                >
                  <TextInput
                    size={"md"}
                    required
                    placeholder="邮箱/手机号"
                    {...form.getInputProps("account")}
                  />

                  <TextInput
                    mt={20}
                    size={"md"}
                    required
                    type="password"
                    placeholder="密码"
                    {...form.getInputProps("password")}
                  />

                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </form>
                <p tw="mt-6 text-xs text-gray-600 text-center">
                  <a
                    href={forgotPasswordUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    忘记密码 ?
                  </a>
                </p>
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  还没有注册账户?
                  <a
                    href={signupUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    去注册
                  </a>
                </p>
              </FormContainer>
            </MainContent>
            <div
              style={{
                color: "#888",
                width: "100%",
                alignContent: "center",
                textAlign: "center",
                fontSize: "14px",
                marginTop: "140px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>2022 © prims.cn. </div>
              <div>南京元物智能科技有限公司</div>
              <a href="https://beian.miit.gov.cn/" target="_blank">
                苏ICP备16049917号-2
              </a>
            </div>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
