import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo-yw-purple.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>元物智能</LogoText>
          </LogoContainer>
          <CompanyDescription>
            我们为用户生产愉悦的数字体验，为客户提供各种平台软件应用的设计、开发、布署及运维服务。
            <hr style={{ margin: "10px 0" }} />
            <div style={{ color: "#666" }}>
              2022 © prims.cn. 南京元物智能科技有限公司 <br />{" "}
              <a href="https://beian.miit.gov.cn/" target="_blank">
                苏ICP备16049917号-2
              </a>
            </div>
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>关于我们</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">常见问题</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">客户支持</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">关于我们</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>服务</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/#/sign-up">注册新用户</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/#/login">用户登录</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/#/contact">留言</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>微信开发</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">小程序</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">微信H5</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">微信小商店</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
