import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import tw from "twin.macro";
import TabGrid from "components/cards/TabCardGrid.js";
import Features from "components/features/ThreeColSimple.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import GetStarted from "components/cta/GetStarted";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import bestSelect from 'images/best-select.svg'
import cooperationImg from 'images/cooperation.svg'
export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-4xl`;
  return (
    <>
      <AnimationRevealPage>
        <Hero
          heading={
            <>
              设备与耗材
              <HighlightedText>针对您的平台和业务.</HighlightedText>
            </>
          }
          description="根据业务需要，我们为您提供业务场景专用的设备及耗材。"
          imageSrc="https://synergetik.de/wp-content/uploads/2012/12/hardware.jpg"
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="马上订购"
          watchVideoButtonText="了解详情"
        />

        {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
        <TabGrid
          heading={
            <>
              所有产品 <HighlightedText>菜单</HighlightedText>
            </>
          }
        />
        <Features
        linkText= {false}
          heading={
            <>
              用心<HighlightedText>服务.</HighlightedText>
            </>
          }
          cards={[
            {
              imageSrc: cooperationImg,
              title: "仅限合作客户",
              description: "我们的代采产品仅限合作客户",
              linkText: false
            },
            {
              imageSrc: bestSelect,
              title: "合理甄选",
              description: "根据客户的业务场景和购买力合理甄选",
              linkText: false
            },
            {
              imageSrc: celebrationIconImageSrc,
              title: "互相成就",
              description: "成就客户成就我",
              linkText: false
            },
          ]}
          imageContainerCss={tw`p-2!`}
          imageCss={tw`w-20! h-20!`}
        />
      </AnimationRevealPage>
      <SimpleFiveColumn />
    </>
  );
};
