import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Header from "components/headers/light.js";
import GetStarted from "components/cta/GetStarted";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import tw from "twin.macro";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import MainFeature from "components/features/TwoColWithButton.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import marketImg from "images/market.png";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import rcImage from "images/R-C.png";
import styled from "styled-components";
import argriculture from "images/argiculture.jpeg";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

   
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const cards = [];
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <>
      <AnimationRevealPage>
        <Header />

        <MainFeature1
          imageRounded
          subheading={<Subheading>专注领域</Subheading>}
          heading="定制化服务."
          description="数据视觉化呈现,数据大屏,Web3D地理展示,微信生态应用,小程序,H5,企业微信生态,开源软件整合优化。"
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        />
        <MainFeature1
          imageRounded
          subheading={<Subheading>产品沉淀</Subheading>}
          heading="农业数字化营运与管理"
          buttonRounded={false}
          description="紧随国家乡村振兴政策导向，我们近年专注于农业产供销及周边生态的数据化服务方案，旨在利用新型的协作形式改善优化传统农业生产中的待提升业务。"
          primaryButtonText="Contact Us"
          imageSrc={argriculture}
          textOnLeft={false}
        />
      </AnimationRevealPage>
   
      <SimpleFiveColumn />
    </>
  );
};
