import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import GetStarted from "components/cta/GetStarted";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import tw from "twin.macro";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import MainFeature from "components/features/TwoColWithButton.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import marketImg from "images/market.png";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import rcImage from "images/R-C.png";

import Features from "components/features/ThreeColWithSideImage.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <>
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <Features
          subheading={<Subheading>细节</Subheading>}
          heading={
            <>
              为您提供优质的<HighlightedText>服务.</HighlightedText>
            </>
          }
        
        />
        <MainFeature
          subheading={<Subheading>富有成效的工作</Subheading>}
          imageSrc={marketImg}
          imageBorder={false}
          imageDecoratorBlob={true}
        />
        <FeatureWithSteps
          subheading={<Subheading>服务流程</Subheading>}
          heading={
            <>
              卓越<HighlightedText>从此</HighlightedText>开始
            </>
          }
          textOnLeft={false}
          imageSrc={rcImage}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        <MainFeature2
          subheading={<Subheading>传递价值</Subheading>}
          heading={
            <>
              我们坚守
              <HighlightedText>价值原则.</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "价格实惠",
              description: "我们尽己所能为客户提供价格公道且符合行业标准的服务",
              iconContainerCss: tw`bg-green-300 text-green-800`,
            },
            {
              Icon: BriefcaseIcon,
              title: "专业精神",
              description: "我们的工作产出基于专业态度.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
      </AnimationRevealPage>
      <SimpleFiveColumn />
    </>
  );
};
